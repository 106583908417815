<template>
  <page-head-wrapper>
    <!-- 操作栏 -->
    <template v-slot:handles-btns>   
      <a-button size="small" @click="onRefresh"><i class="e-icon refresh"></i>刷新</a-button>
    </template>

    <!-- 搜索栏 -->
    <template v-slot:handles-right>
      <a-form-model layout="inline"
        :form="searchForm"
        @submit="handleSearchSubmit"
        @submit.native.prevent
      >
        <a-form-model-item>
          <a-input style="width: 240px" v-model="searchForm.keyword" placeholder="请输入企业名称/SN编号"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select style="width: 160px" v-model="searchForm.companyType " placeholder="请选择企业类型"
            :getPopupContainer="node => node.parentNode || document.body"
          >
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option value="1">
              工商型
            </a-select-option>
            <a-select-option value="2">
              个人型
            </a-select-option>
          </a-select>
        </a-form-model-item>    
        <a-form-model-item style="margin-right: 0">    
          <a-button class="mp-grey-btn" html-type="submit">
            查询
          </a-button>
          <a-button class="mp-grey-btn" style="margin-left: 8px" @click="resetSearchForm">
            重置
          </a-button>
        </a-form-model-item>    
      </a-form-model>
    </template>

    <!-- 列表 -->
    <div class="mp-table-wrap">
      <a-table :columns="columns" 
        :data-source="tableData" 
        rowKey="companyId"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
      >   
          <div slot="serial" slot-scope="text,record,index">{{index+1}}</div>
          <div slot="companyType" slot-scope="text">{{text | companyTypeText}}</div>
          <div slot="handles" slot-scope="text, record">
            <a-button type="link" size="small" @click="viewCertificateHandle(record)">查看证书</a-button>
            <a-button type="link" size="small" @click="toDetail(record)">查看详情</a-button>
          </div>
      </a-table>
    </div>

    <!-- 查看会员证书 -->
    <a-modal v-model="certificateVisible" title="证书照片" centered :width="806" :footer="null">
      <div class="certificate-img-box">
        <span class="title">{{certificate.companyName}}</span>
        <span class="code">No:{{certificate.number}}</span>
      </div>
    </a-modal>
  </page-head-wrapper>
</template>

<script>
import { memberCompanyList } from '@/api/company'
const columns = [
  {
    align: 'center',
    title: '序号',
    width: '80px',
    scopedSlots: { customRender: 'serial'}
  },
  {
    align: 'center',
    title: '编码',
    dataIndex: 'number'
  },
  {
    align: 'center',
    title: '企业名称',
    dataIndex: 'companyName'
  },
  {
    align: 'center',
    title: '企业类型',
    dataIndex: 'companyType',
    scopedSlots: { customRender: 'companyType'}
  },
  {
    align: 'center',
    title: '联系人员',
    dataIndex: 'companyContactPerson'
  },
  {
    align: 'center',
    title: '联系电话',
    dataIndex: 'companyContactPhone'
  },
  {
    align: 'center',
    title: '相关操作',
    width: 200,
    scopedSlots: { customRender: 'handles'}
  },
]
export default {
  name: 'static-data-company-member-list',
  keep_alive_tab: true,
  data() {
    return {
      // 搜索表单
      searchForm: {
        keyword: '',
        companyType: '',
      },
       
      // 列表
      tableData: [],
      columns,
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: total => `共${total}条`,
        showSizeChanger: true,
        showQuickJumper: true
      },

      certificateVisible: false,
      certificate: {
        companyName: '',
        number: ''
      }
    }
  },
  filters: {
    companyTypeText: function(value) {
      return value == 1 ? '工商型': value == 2 ? '个人型' : '-'
    }
  },
  created() {
    this.memberCompanyList()
  },
  methods: {
    memberCompanyList() {
      let {current, pageSize:size} = this.pagination
      let {keyword, companyType} = this.searchForm
      this.loading = true
      memberCompanyList({
        current,
        size,
        keyword,
        companyType
      }).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.tableData = res.data.records.map(item => {
            return {
              ...item,
              number: item.number || '-',
              companyName: item.companyName || '-',
              companyContactPerson: item.companyContactPerson || '-',
              companyContactPhone: item.companyContactPhone || '-',
            }
          })
          this.pagination.total = res.data.total
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        console.warn(err)
      }).finally(() => {
        this.loading = false
      })
    },
    // 搜索表单
    handleSearchSubmit() {
      console.log(this.searchForm)
      this.pagination.current = 1
      this.memberCompanyList()
    },
    // 重置搜索
    resetSearchForm() {
      console.log('resetSearchForm')
      this.pagination.current = 1
      this.searchForm.keyword = ''
      this.searchForm.companyType = ''
      this.memberCompanyList()
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      this.memberCompanyList()
    },
    toDetail(record) {
      this.pushTabPage({
        path: '/static-data/company/member/detail?id=' + record.companyId, 
      })
    },
    // 刷新操作
    onRefresh() {
      this.resetSearchForm()
    },
    // 查看证书
    viewCertificateHandle(record) {
      this.certificate.companyName = record.companyName
      this.certificate.number = record.number
      this.certificateVisible = true
    },
  }
}
</script>